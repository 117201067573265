import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageServiceKeys } from './Model/StorageServiceKeys';
import { HttpClient } from '@angular/common/http';
import { IOAuthTokenData } from './Model/IOauth2Model';
@Injectable()
export class TokenService {
  accessTokenObservable = new BehaviorSubject<string>('');
  serviceNowInstanceURL: string;
  AMCAppServiceNowURL: string;
  clientId: string;
  clientSecret: string;
  constructor(
    private _http: HttpClient,
  ) { }

  /**
   * 
   * @param accessToken 
   */
  setAccessToken(accessToken: string) {
    this.accessTokenObservable.next(accessToken);
  }


  getAccessToken(): string {
    return this.accessTokenObservable.value;
  }

  setRefreshTokenParams(
    serviceNowInstanceURL: string,
    AMCAppServiceNowURL: string,
    clientId: string,
    clientSecret: string
  ) {
    this.serviceNowInstanceURL = serviceNowInstanceURL;
    this.AMCAppServiceNowURL = AMCAppServiceNowURL;
    this.clientId = clientId;
    this.clientSecret = clientSecret;
  }

  storeAccessToken(tokenData: IOAuthTokenData) {
    localStorage.setItem(StorageServiceKeys.snAccessToken, tokenData.access_token);
    this.setAccessToken(tokenData.access_token);

    const expires_in_seconds = Number.parseInt(tokenData.expires_in);
    const expires_in = (Date.now() + expires_in_seconds * 1000) + "";
    localStorage.setItem(StorageServiceKeys.snAccessTokenExpiration, expires_in);

  }

  getNewAccessTokenWithRefreshToken(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!localStorage.getItem(StorageServiceKeys.snRefreshRequestIsSentAlready)) {
        localStorage.setItem(StorageServiceKeys.snRefreshRequestIsSentAlready, "true");

        try {
          this._http.post('RefreshToken', {
            client_id: this.clientId,
            client_secrect: this.clientSecret,
            instance_url: this.serviceNowInstanceURL,
          }).subscribe(
            response => {
              this.storeAccessToken(response);
              localStorage.removeItem(StorageServiceKeys.snRefreshRequestIsSentAlready);
              resolve();
            },
            async (error) => {
              if (error.status === 400 && error.error === "No refresh token! Open auth window") {
                try {
                  await this.getNewAccessTokenByOpeningNewWindow();
                  localStorage.removeItem(StorageServiceKeys.snRefreshRequestIsSentAlready);
                  resolve();
                } catch (_) {
                  localStorage.removeItem(StorageServiceKeys.snRefreshRequestIsSentAlready);
                  reject();
                }
              } else {
                localStorage.removeItem(StorageServiceKeys.snRefreshRequestIsSentAlready);
                reject();
              }
            }
          )
        } catch (_) {
          localStorage.removeItem(StorageServiceKeys.snRefreshRequestIsSentAlready);
          reject();
        }
      } else {
        let timeout = 30;
        let timer = setInterval(async () => {
          // TODO1: what if the agent closes the window?
          if (localStorage.getItem(StorageServiceKeys.snAccessToken)) {
            clearInterval(timer);
            resolve();
          } else {
            timeout--;
            if (timeout <= 0) {
              clearTimeout(timer)
              reject();
            }
          }
        }, 1000);
      }

    })
  }


  getNewAccessTokenWithAuthCode(): Promise<void> {
    return new Promise((resolve, reject) => {
      const code = localStorage.getItem(StorageServiceKeys.snAuthorizationCode);

      if (!localStorage.getItem(StorageServiceKeys.snAuthRequestIsSentAlready)) {
        localStorage.setItem(StorageServiceKeys.snAuthRequestIsSentAlready, "true");
        if (code) {
          try {
            this._http.post('Auth', {
              code: code,
              client_id: this.clientId,
              client_secrect: this.clientSecret,
              instance_url: this.serviceNowInstanceURL,
              redirect_uri: this.AMCAppServiceNowURL
            }).subscribe(
              response => {
                this.storeAccessToken(response);
                resolve();
                localStorage.removeItem(StorageServiceKeys.snAuthRequestIsSentAlready);
              },
              _ => {
                // Handle errors
                localStorage.removeItem(StorageServiceKeys.snAuthRequestIsSentAlready);
                reject();
              }
            )
          } catch (error) {
            localStorage.removeItem(StorageServiceKeys.snAuthRequestIsSentAlready);
            reject();
          }
        } else {
          localStorage.removeItem(StorageServiceKeys.snAuthRequestIsSentAlready);
          reject();
        }
      } else {
        setTimeout(() => {
          if (!localStorage.getItem(StorageServiceKeys.snAuthRequestIsSentAlready)) {
            if (localStorage.getItem(StorageServiceKeys.snAccessToken)) {
              resolve();
            } else {
              reject();
            }
          }
        }, 1000);
      }

    })
  }

  openAuthCodeWindow() {
    window.open(
      `${this.serviceNowInstanceURL}/oauth_auth.do?response_type=code&redirect_uri=${this.AMCAppServiceNowURL}&client_id=${this.clientId}&state=token`,
      '_blank'
    );
  }


  getNewAccessTokenByOpeningNewWindow(): Promise<void> {
    // This function is called when the error for an expired token is recieved
    return new Promise((resolve, reject) => {
      if (!localStorage.getItem(StorageServiceKeys.snAuthWindowOpened)) {
        localStorage.setItem(StorageServiceKeys.snAuthWindowOpened, "true");
        this.openAuthCodeWindow();
      }

      let timeout = 30;
      let timer = setInterval(async () => {
        // TODO1: what if the agent closes the window?
        if (!localStorage.getItem(StorageServiceKeys.snAuthWindowOpened)) {
          clearInterval(timer);
          const code = localStorage.getItem(StorageServiceKeys.snAuthorizationCode);
          if (code) {
            try {
              await this.getNewAccessTokenWithAuthCode();
              resolve();
            } catch (error) {
              reject();
            }
          }
        } else {
          timeout--;
          if (timeout <= 0) {
            clearInterval(timer);
            window.localStorage.setItem(
              StorageServiceKeys.snAccessToken,
              window.location.hash
            );
            reject();
          }
        }
      }, 1000);
    });

  }
}
